import OperationsIcon  from 'assets/icons/sidebar/OperationsIcon.svg';
import OverviewIcon  from 'assets/icons/sidebar/OverviewIcon.svg';
import ReportsIcon from 'assets/icons/sidebar/ReportsIcon.svg';
import OperationsIconSelected  from 'assets/icons/sidebar/OperationsIconSelected.svg'
import OverviewIconSelected  from 'assets/icons/sidebar/OverviewIconSelected.svg'
import ReportsIconSelected  from 'assets/icons/sidebar/ReportsIconSelected.svg'
import Overview from 'app/Dashboard/Overview';
import Reports from 'app/Dashboard/Reports';
import { InventoryDomains, ClientManagement, AdminUserManagement, Roles } from 'app/Dashboard/Operations'
import {Pubmatic, Equativ} from '../app/Dashboard/Integrations'

const dashboardPages = [
    {
        icon: OverviewIcon,
        selected: OverviewIconSelected,
        text: "Overview",
        path: "/overview",
        key: "overview",
        component: Overview
    }, {
        icon: ReportsIcon,
        selected: ReportsIconSelected,
        text: "Reports",
        path: "/reports",
        key: "reports",
        component: Reports
    }, {
        icon: ReportsIcon,
        selected: ReportsIconSelected,
        text: "Integrations",
        path: "/integrations",
        key: "integrations",
        subItems: [
            {
                text: "Pubmatic",
                path: "/pubmatic",
                key: "Pubmatic",
                component: Pubmatic,
            },
            {
                text: "Equativ",
                path: "/equativ",
                key: "Equativ",
                component: Equativ,
            }
        ]    
    }, {
        icon: OperationsIcon,
        selected: OperationsIconSelected,
        text: "Operations",
        path: "/operations",
        key: "operations",
        subItems: [
            {
                text: "Inventory domains",
                path: "/inventory-domains",
                key: "inventoryDomains",
                component: InventoryDomains,
            }, {
                text: "Client management",
                path: "/client-management",
                key: "clientManagement",
                component: ClientManagement,
            },{
                text: "Admin user management",
                path: "/admin-user-management",
                key: "userManagement",
                component: AdminUserManagement,
            },{
                text: "Roles",
                path: "/roles",
                key: "roles",
                component: Roles,
            }
        ]
    }
]

export default dashboardPages
