import React from 'react'
import TextArea from './TextArea'


function Keywords(props) {
    const { keywords, setKeywords } = props

    return (
        <div className='p-0 mb-responsive'>
            <TextArea 
                rows="3" 
                className="rounded" 
                id="keywords-custom-query"
                label="Keywords"
                placeholder="Paste/type your keywords here."
                onChange={e => setKeywords(e.target.value)}
                value={ keywords }
            />
        </div>
    )
}

export default Keywords